<template>
  <b-card-code
      no-body
      title="Withdraw List"
  >
    <div class="dt_adv_search ml-1 mr-1">
      <div class="row">

        <div class="col-3">
          <select
              v-model="filter.status"
              class="form-control form-control-sm"
          >
            <option value="">
              -- All Status --
            </option>
            <option value="pending">
              Pending
            </option>
            <option value="success">
              Success
            </option>
            <option value="failed">
              Failed/Rejected
            </option>
          </select>
        </div>
        <div class="col-3" />

        <div class="col-md-6">
          <button
              class="btn btn-success btn-sm float-right"
              @click="exportWithdraw()"
          >
            <feather-icon
                icon="DownloadCloudIcon"
            />
            Export Withdraw
          </button>
        </div>

      </div>

    </div>
    <b-modal
        id="detail-modal"
        no-close-on-backdrop
        title="Detail Withdraw"
    >

      <table
          v-if="detail != null"
          class="table table-hover"
      >
        <tr>
          <td class="ml-0 pl-0">
            User
          </td>
          <td v-if="detail.user != null">
            <strong>{{ detail.user.name }}</strong>
            <router-link :to="{ name: 'users.show', params: { id: detail.user.id } }">
              <feather-icon
                  icon="ExternalLinkIcon"
              />
            </router-link>
          </td><td v-else>
          -
        </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">
            Asset
          </td>
          <td>
            <strong>{{ detail.commodity.name }}</strong>
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">
            Depository
          </td>
          <td>
            <strong>{{ detail.warehouse.name }}</strong>
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">
            Amount
          </td>
          <td>
            <strong>{{ detail.amount_formatted }}</strong>
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">
            Type
          </td>
          <td>
            <strong class="text-capitalize">{{ detail.type == null ? '-' : detail.type.replace('_', ' ') }}</strong>
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">
            Registry Number
          </td>
          <td>
            <strong>{{ detail.registryNumber == null ? '-' : detail.registryNumber.registry.name + ' - ' + detail.registryNumber.registry_number }}</strong>
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">
            Withdraw Time
          </td>
          <td>
            <strong>{{ detail.withdraw_time }}</strong>
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">
            Other Information
          </td>
          <td>
            Name on Certificate: <strong>{{ detail.name_on_certificate == null ? '-' : detail.name_on_certificate }}</strong><br>
            Office Address: <strong>{{ detail.office_address == null ? '-' : detail.office_address }}</strong><br>
            Period: <strong>{{ detail.period_start_date == null ? '-' : detail.period_start_date }} {{ detail.period_end_date == null ? '-' : detail.period_end_date }}</strong><br>
            Transaction Number: <strong>{{ detail.transaction_number == null ? '-' : detail.transaction_number }}</strong><br>
            Purpose: <strong>{{ detail.purpose == null ? '-' : detail.purpose }}</strong><br>
          </td>
        </tr>

        <tr>
          <td class="ml-0 pl-0">
            Certificate
          </td>
          <td>
            <strong v-if="detail.file_attachment_url"><a
                :href="detail.file_attachment_url"
                target="_blank"
            >Open File</a></strong><span v-else>-</span>
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">
            Status
          </td>
          <td>
            <span
                class="badge text-capitalize badge-pill"
                :class="getStatusColor(detail.status)"
            >{{ detail.status }}</span>
          </td>
        </tr>
      </table>

      <template
          v-if="detail != null && detail.status == 'pending'"
          #modal-footer="{}"
      >
        <b-button
            variant="danger"
            @click="rejectWithdraw(detail)"
        >
          Reject Withdraw
        </b-button>
        <b-button
            variant="success"
            @click="approveWithdraw(detail)"
        >
          Approve Withdraw
        </b-button>
      </template>
    </b-modal>
    <br>
    <div class="table-responsive">
      <table class="table b-table table-fixed">
        <thead>
        <tr>
          <th>User</th>
          <th>Type</th>
          <th>Asset</th>
          <th>Amount</th>
          <th>Registry Number</th>
          <th>Status</th>
          <th>Withdraw Time</th>
          <th />
        </tr>
        </thead>
        <tbody>
        <tr v-if="isLoading">
          <td
              colspan="8"
              class="text-center"
          >
            <b-spinner
                class="mb-1"
                variant="primary"
            /><br>
            Loading...
          </td>
        </tr>
        <tr
            v-for="item in result.data"
            v-else
            :key="item.id"
        >
          <td v-if="item.user != null">
            <router-link :to="{ name: 'users.show', params: { id: item.user.id } }">
              {{ item.user.name }}<br><small>{{ item.user.email }}</small>
            </router-link>
          </td>
          <td v-else>
            -
          </td>
          <td class="text-capitalize">
            {{ item.type == null ? '-' : item.type.replace('_', ' ') }}
          </td>
          <td>
            {{ item.commodity.name }}
          </td>
          <td>{{ item.amount_formatted }}</td>
          <td>{{ item.registryNumber == null ? '-' : item.registryNumber.registry.name + ' - ' + item.registryNumber.registry_number }}</td>
          <td class="text-capitalize">
              <span
                  class="badge text-capitalize badge-pill"
                  :class="getStatusColor(item.status)"
              >{{ item.status }}</span>
          </td>
          <td>{{ item.withdraw_time }}</td>
          <td>
            <div
                class="btn-group"
                role="group"
            >
              <button
                  class="btn btn-info btn-sm"
                  style="margin-left: 2px;"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Detail Withdraw"
                  @click="detailWithdraw(item)"
              >
                <feather-icon
                    icon="InfoIcon"
                />
              </button>
              <button
                  v-if="checkPermission('update commodity withdraw')"
                  class="btn btn-warning btn-sm"
                  style="margin-left: 2px;"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Edit"
                  @click="editItem(item)"
              >
                <feather-icon
                    icon="EditIcon"
                />
              </button>
              <button
                  v-if="item.status == 'pending' && checkPermission('approve withdraw')"
                  class="btn btn-success btn-sm"
                  style="margin-left: 2px;"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Approve"
                  @click="approveWithdraw(item)"
              >
                <feather-icon
                    icon="CheckCircleIcon"
                />
              </button>
              <button
                  v-if="item.status == 'pending' && checkPermission('reject withdraw')"
                  class="btn btn-danger btn-sm"
                  style="margin-left: 2px;"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Reject Withdraw"
                  @click="rejectWithdraw(item)"
              >
                <feather-icon
                    icon="XCircleIcon"
                />
              </button>
            </div>
          </td>
        </tr>
        <tr v-if="result.total == 0 && !isLoading">
          <td
              colspan="8"
              class="text-center"
          >
            Data is empty.
          </td>
        </tr>
        </tbody>
      </table>

      <div
          v-if="result.total > 0"
          class="m-1"
      >
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination
                :data="result"
                :limit="4"
                align="right"
                @pagination-change-page="getData"
            />
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="form-modal"
        no-close-on-backdrop
        :title="editUuid != null ? 'Edit' : 'Add'"
    >
      <div class="form">

        <div class="form-group">
          <label for="">Certificate:</label><br>
          <input
              id="file"
              ref="file"
              type="file"
              @change="changeFileAttachment()"
          >
        </div>

      </div>

      <template #modal-footer="{}">
        <!-- <b-button v-if="editUuid == null" variant="success" @click="createItem()">
          Save Topup
        </b-button> -->
        <b-button
            variant="success"
            @click="updateItem()"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import { EditIcon } from 'vue-feather-icons'
import FileSaver from 'file-saver'
import moment from 'moment'

export default {
  title() {
    return `Asset Withdraw List`
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    EditIcon,
    BSpinner,
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      currentPage: 1,
      result: {},
      filter: {
        status: this.$route.query.status == null ? '' : this.$route.query.status,
        amount: '',
      },
      detail: null,
      isLoading: false,
      editUuid: null,
      formPayload: {
        file_attachment: null,
      },
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData(page = 1) {
      this.isLoading = true
      this.currentPage = page
      const queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/commodity-withdraw', {
        params: queryParams,
      })
          .then(response => {
            this.result = response.data.data
            this.isLoading = false
          })
    },
    detailWithdraw(item) {
      this.detail = item

      this.$bvModal.show('detail-modal')
    },
    exportWithdraw() {
      const queryParams = this.filter

      this.$http.get('/admin/commodity-withdraw/export/csv', {
        params: queryParams,
        responseType: 'blob',
      })
          .then(response => {
            const timestamp = moment().format('YYYY:MM:DD:HH:mm:ss')
            const fileName = `commodity-withdraw-${timestamp}.csv`
            FileSaver.saveAs(response.data, fileName)
          })
    },
    changeFileAttachment() {
      this.formPayload.file_attachment = this.$refs.file.files[0]
    },
    editItem(item) {
      this.editUuid = item.uuid
      this.$bvModal.show('form-modal')
    },
    updateItem() {
      const form = new FormData()
      form.append('_method', 'PATCH')

      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }

      this.$http.post(`/admin/commodity-withdraw/${this.editUuid}`, form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
          .then(response => {
            this.$bvModal.hide('form-modal')
            this.getData(this.currentPage)
            successNotification(this, 'Success', 'Asset withdraw successfully updated!')
          })
          .catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)
            }
          })
    },
    approveWithdraw(item) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure want mark this withdraw as success?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true

          this.$http.post(`/admin/commodity-withdraw/${item.uuid}/approve`)
              .then(response => {
                this.getData(this.currentPage)
                this.$bvModal.hide('detail-modal')
                this.$swal({
                  icon: 'success',
                  title: 'Approved!',
                  text: 'Withdraw status changed to success.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })

                this.isLoading = false
              })
              .catch(error => {
                if (error.response.data.meta.messages.length > 0) {
                  errorNotification(this, 'Oops!', error.response.data.meta.messages)
                }
                this.isLoading = false
              })
        }
      })
    },
    rejectWithdraw(item) {
      this.$swal({
        title: 'Are you sure?',
        text: "User's commodity balance will be refund to user",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true

          this.$http.post(`/admin/commodity-withdraw/${item.uuid}/reject`)
              .then(response => {
                this.getData(this.currentPage)
                this.$bvModal.hide('detail-modal')
                this.$swal({
                  icon: 'success',
                  title: 'Rejected!',
                  text: 'Withdraw status changed to rejected/failed.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })

                this.isLoading = false
              })
              .catch(error => {
                if (error.response.data.meta.messages.length > 0) {
                  errorNotification(this, 'Oops!', error.response.data.meta.messages)
                }
                this.isLoading = false
              })
        }
      })
    },
    getStatusColor(status) {
      switch (status) {
        case 'success':
          return 'badge-light-success'
          break

        case 'expired':
          return 'badge-light-danger'
          break

        case 'failed':
          return 'badge-light-danger'
          break

        default:
          return 'badge-light-secondary'
          break
      }
    },
  },
}
</script>
